@if (form) {
    <form
            *transloco="let t; prefix: 'stDiagnosisCodeSearch'"
            [formGroup]="form"
            (submit)="submit($event)">
        <h4>{{ t('label.title') }}</h4>

        <big-ui-form-row
            #searchTerm
            [id]="'searchTerm'"
            [label]="t('label.searchTerm')"
            [isRequired]="required"
            [showError]="hasBeenSubmitted"
            [errorOverrides]="autocompleteErrorOverrides"
            [errors]="form.controls.searchTerm.errors">
            <big-ui-autocomplete-input
                    [id]="'searchTerm'"
                    formControlName="searchTerm"
                    name="searchTerm"
                    [isValid]="hasBeenSubmitted && form.controls.searchTerm.valid"
                    [isInvalid]="hasBeenSubmitted && !form.controls.searchTerm.valid"
                    [isRequired]="required"
                    [optionsValueTransformer]="transformOptionValue"
                    [options]="diagnosisCodeOptions()"
                    [requireSelection]="false"
                    (inputChanges)="onAutocompleteInputChange($event)"
                    [bigStFieldTracking]="{
                        metadata,
                        fieldName: searchTerm.label!,
                        valid: !hasBeenSubmitted || form.controls.searchTerm.valid
                    }" />
        </big-ui-form-row>

        <big-ui-button
                [buttonStyle]="'secondary'"
                class="block mt-10"
                (clicked)="submit($event)">
            {{ t('label.submit') }}
        </big-ui-button>
    </form>
}

@if (this.form?.valid && showResults()) {
    <ng-container *transloco="let t; prefix: 'stDiagnosisCodeSearch'">
        <h4 id="resultsHeader" class="mt-10">
            {{ t('label.resultCount', { hits: resultCount() }) }}
        </h4>

        @if (resultCount() > 0) {
            <big-ui-accordion>
                @for (item of results(); track item.code; let index = $index) {
                    <big-ui-accordion-item [index]="index" [title]="item.code + ' - ' + item.title" [isOpen]="queryParams()?.code === item.code"
                                           (opening)="handleAccordionItemOpen($event)">
                        <big-service-tools-diagnosis-code-search-item
                                [parent]="item.parent"
                                [children]="item.children"
                                [inclusions]="item.inclusions"
                                [exclusions]="item.exclusion"
                                [synonyms]="item.synonyms" />
                    </big-ui-accordion-item>
                }
            </big-ui-accordion>
        }
    </ng-container>
}
